import KnTabs from '../../../shared/components/KnTabs.vue';
export default {
  name: 'SchoolControlReportIndex',
  components: { KnTabs },
  mixins: [],
  props: {},
  data() {
    return {
      valueDeterminate: 33,
      tabs: [
        {
          name: 'Control Escolar Reportes Alumnos',
          value: 33,
          title: 'Listas de alumnos',
        },
        // {
        //   name: 'Control Escolar Reportes Movimientos',
        //   value: 66,
        //   title: 'Alta y bajas de alumnos',
        // },
        // {
        //   name: 'Control Escolar Reportes Rendimiento',
        //   value: 100,
        //   title: 'Control de rendimiento',
        // },
      ],
    };
  },
  computed: {},
  created() {},
  methods: {
    setTabValue(val) {
      this.valueDeterminate = val;
    },
  },
};
